<template>
  <form
    v-if="!projectId || project != null"
    ref="jestCard"
    class="ProjectEditForm__form"
    @submit.prevent="validateAndSubmit()"
  >
    <AppTextField
      key="name"
      v-model="form.name"
      :placeholder="$t('project.ProjectName')"
      outlined
      dense
      type="text"
      name="name"
      maxlength="128"
      :disabled="!canEdit"
      :autofocus="!projectId"
      :error-messages="getErrors('name')"
      class="ProjectEditForm__field"
    >
      <template #append>
        <v-icon
          color="#D3D6E2"
          v-text="'mdi-asterisk'"
        />
      </template>
      <template #prepend>
        <span
          class="ProjectEditForm__label mb-3"
          v-text="$t('project.ProjectName')"
        />
      </template>
    </AppTextField>

    <div class="ProjectEditForm__label mb-3">
      {{ $t('project.StartEnd') }}
    </div>
    <div class="d-flex">
      <AppDatePicker
        key="startDate"
        v-model="form.startDate"
        :outlined="true"
        dense
        type="text"
        name="startDate"
        :disabled="!canEdit"
        text-field-style="flex: 0 1 138px"
        :error-messages="getErrors('startDate')"
        @input="$nextTick(() => $refs.completionDate.open())"
      />

      <AppDatePicker
        ref="completionDate"
        key="completionDate"
        v-model="form.completionDate"
        class="ProjectEditForm__field"
        :outlined="true"
        dense
        type="text"
        name="completionDate"
        text-field-class="ml-4"
        text-field-style="flex: 0 1 138px"
        :disabled="!canEdit"
        :error-messages="getErrors('completionDate')"
      />
    </div>

    <AppTextarea
      key="scope"
      v-model="form.scope"
      :placeholder="$t('project.Scope')"
      class="ProjectEditForm__field"
      outlined
      dense
      auto-grow
      rows="1"
      :max-height="168"
      type="text"
      name="scope"
      :disabled="!canEdit"
      :error-messages="getErrors('scope')"
    >
      <template #prepend>
        <span
          class="ProjectEditForm__label mb-3"
          v-text="$t('project.ScopeOptional')"
        />
      </template>
    </AppTextarea>

    <AppTextarea
      key="description"
      v-model="form.description"
      :placeholder="$t('project.ProjectDescription')"
      class="ProjectEditForm__field"
      outlined
      dense
      auto-grow
      rows="1"
      :max-height="168"
      type="text"
      name="description"
      :disabled="!canEdit"
      :error-messages="getErrors('description')"
    >
      <template #prepend>
        <span
          class="ProjectEditForm__label mb-3"
          v-text="$t('project.ProjectDescriptionOptional')"
        />
      </template>
    </AppTextarea>

    <AppSelect
      key="projectType"
      v-model="form.projectType"
      :placeholder="$t('project.ProjectType')"
      class="ProjectEditForm__field"
      outlined
      dense
      type="text"
      name="projectType"
      :disabled="!canEdit"
      :items="[$t('project.Pentest'), $t('project.SecurityAudit'), $t('project.MobileAppSecurityAnalisys')]"
      :error-messages="getErrors('projectType')"
      margins-with-hidden-details="mb-6"
      margins-with-details="mb-6"
    >
      <template #prepend>
        <span
          class="ProjectEditForm__label mb-3"
          v-text="$t('project.ProjectTypeOptional')"
        />
      </template>
    </AppSelect>

    <div class="ProjectEditForm__label mb-3">
      {{ $t('project.LocationApiary') }}
    </div>
    <ProjectGroupSelect
      key="groupID"
      v-model="form.groupID"
      :disabled="!canEdit"
      class="ProjectEditForm__field"
      name="groupID"
      :error-messages="getErrors('groupID')"
    />
  </form>
</template>

<script>
import i18n from '../i18n'
import moment from 'moment'
import * as R from 'ramda'
import { required, maxLength } from 'vuelidate/lib/validators'

import { PROJECT_PERMISSION_LEVEL as PERM, PROJECT_CONNECTION_TYPE as CONNECT } from '../constants'
import { reportError, replaceRoute } from '../helpers'
import { isSameMomentOrAfter } from '../validators'

import Dialog from '../store/orm/dialog'
import Project from '../store/orm/project'

import ProjectGroupSelect from './ProjectGroupSelect'

const validationErrors = {
  'name-required': i18n.t('project.validationErrors.NameRequired'),
  'name-maxLength': i18n.t('project.validationErrors.NameMaxLength'),
  'startDate-required': i18n.t('project.validationErrors.StartDateRequired'),
  'completionDate-required': i18n.t('project.validationErrors.CompletionDateRequired'),
  'completionDate-isAfter': i18n.t('project.validationErrors.CompletionDateIsAfter'),
}

const formValidation = {
  name: { required, maxLength: maxLength(128) },
  scope: { /* required */ },
  description: { /* required */ },
  startDate: { required },
  completionDate: { required, isAfter: isSameMomentOrAfter('startDate') },
  projectType: { /* required */ },
  groupID: {},
}

export default {
  name: 'ProjectEditForm',

  components: {
    ProjectGroupSelect,
  },

  inheritAttrs: false,

  props: {
    appConnectionId: { type: String, default: null },
    connectionType: { type: String, default: CONNECT.project },
    projectId: { type: String, default: null },
  },

  data() {
    return {
      form: null,
      connectNewProjectToToHive: false,

      saving: false,
    }
  },

  validations: {
    form: formValidation,
  },

  computed: {

    project() {
      const { projectId } = this
      return projectId && Project.find(projectId)
    },

    currentUser() { return this.$store.getters['user/current'] },

    canEdit() {
      const { currentUser, projectId, project } = this
      if (!projectId) return true
      if (currentUser == null) return null
      if (currentUser.isAdmin) return true
      if (project == null) return null
      return project.permission === PERM.OWNER.value
    },

    firstError() {
      const fields = Object.keys(formValidation)
      for (const field of fields) {
        const errors = this.getErrors(field)
        if (errors.length) return errors[0]
      }
      return null
    },
  },

  watch: {

    project: {
      handler: 'initForm',
      immediate: true,
    },
  },

  methods: {
    initForm() {
      const { project } = this
      this.form = project
        ? R.pipe(
          R.pick(['name', 'scope', 'description', 'startDate', 'completionDate', 'projectType', 'groupID']),
          R.clone,
        )(project)
        : this.getDefaultForm()
      this.connectNewProjectToToHive = false
      this.$v.$reset()
    },

    getErrors(field) {
      const v = this.$v.form[field]

      if (!v.$dirty) return []
      return Object.entries(v)
        .filter(([k, _]) => !k.startsWith('$'))
        .filter(([_, v]) => !v)
        .map(([k, _]) =>
          validationErrors[`${field}-${k}`] ||
          `${this.$t('project.validationErrors.ValidationError')} ${field} ${k}`)
    },

    async validateAndSubmit(localUsers, remoteUsers) {
      const { form, projectId } = this

      // 1. handle validation
      this.$v.$touch() // highlight errors
      if (this.$v.$error) {
        this.$store.commit('$snackbar/setMessage', {
          message: this.firstError || this.$t('project.fixErrorsM'),
        })
        return
      }

      // 2. determine action and payload
      const isNew = projectId == null
      const action = isNew ? '$create' : '$patch'
      const project = R.pipe(
        R.clone,
        R.when(() => !isNew, R.assoc('id', projectId)),
        R.over(R.lensProp('projectType'), R.defaultTo('')),
        R.over(R.lensProp('groupID'), R.defaultTo(undefined)),
      )(form)

      // 3. save project
      this.saving = true
      try {
        const projectId = (this.appConnectionId && this.connectionType === CONNECT.app)
          ? await this.$store.dispatch('appConnections/createProject', { applicationConnectId: this.appConnectionId, project, localUsers, remoteUsers })
          : await Project.dispatch(action, { project, localUsers })

        await this.onSuccess(projectId, isNew)
      } catch (e) {
        reportError(e)
      } finally {
        this.saving = false
      }
    },

    async onSuccess(projectId, isNewProject) {
      const { $router } = this
      if (!isNewProject) {
        await Project.dispatch('$getList')
      }

      const nextRoute = {
        name: 'ProjectDashboard',
        params: { projectId },
      }

      await this.$nextTick()
      await replaceRoute($router, nextRoute)
      if (this.connectionType === CONNECT.project && isNewProject) {
        await Dialog.open({
          componentName: 'HawserConnectionDialog',
          props: { projectId },
        })
      }
    },

    getDefaultForm() {
      return {
        name: '',
        scope: '',
        description: '',
        startDate: moment().format('YYYY-MM-DD'),
        completionDate: moment().format('YYYY-MM-DD'),
        projectType: '',
        groupID: null,
      }
    },
  },

}
</script>

<style lang="sass" scoped>
.ProjectEditForm
  background: white
  overflow: hidden

  &__field
    display: flex
    flex-direction: column

  &__label
    color: #41415A
    font-size: 14px
    line-height: 20px
    font-weight: 500

</style>
